import tinycolor from 'tinycolor2'
import { Sketch } from './Sketch'
import { findNearDivisor } from './utils'

type Dot = {
  x: number
  y: number
  scale: number
  offsetAngle1: number
  offsetAngle2: number
  color1: string
  color2: string
}

export class ChaosGrid implements Sketch {
  private canvas: HTMLCanvasElement
  private ctx: CanvasRenderingContext2D

  width: number
  height: number

  dotLayoutSize: number
  dotSize: number
  dotOffset: number

  dots: Dot[] = []
  mouse = { x: 0, y: 0 }

  effectPos = { x: 0, y: 0 }
  effectVel = { x: 0, y: 0 }
  
  lastMove = new Date().getTime()
  activityFactor = 0
  positiveDAF = 0.02
  negativeDAF = 0.005

  setup = async (canvas: HTMLCanvasElement): Promise<number> => {
    this.canvas = canvas
    this.ctx = (ctx_opt => {
      if (ctx_opt === null) throw new Error('Context is null')
      else return ctx_opt
    })(this.canvas.getContext('2d'))

    this.resize()

    this.effectPos = { x: this.width / 2, y: this.height / 2 }
    this.mouse = { x: this.width / 2, y: this.height / 2 }

    window.addEventListener('resize', this.resize)
    document.addEventListener('mousemove', this.handleInteraction)
    document.addEventListener('touchmove', e => {
      e.preventDefault()
      this.handleInteraction(e.touches[0])
    })

    this.dots = this.createDots()

    return 1
  }

  draw = (): void => {
    const accel = 0.02
    const damping = 0.9
    this.effectVel.x += (this.mouse.x - this.effectPos.x) * accel
    this.effectVel.y += (this.mouse.y - this.effectPos.y) * accel
    this.effectVel.x *= damping
    this.effectVel.y *= damping
    this.effectPos.x += this.effectVel.x
    this.effectPos.y += this.effectVel.y

    const timeSinceLastMove = (new Date().getTime() - this.lastMove) / 1e3
    if (timeSinceLastMove >= 0.1) this.activityFactor -= this.negativeDAF
    this.activityFactor = this.clamp(this.activityFactor, 0, 1)

    const triggerDistance =
      Math.max(this.width, this.height) * 0.1 * this.activityFactor

    this.ctx.fillStyle = 'black'
    this.ctx.fillRect(0, 0, this.width, this.height)

    this.dots.forEach(dot => {
      const distance = Math.hypot(dot.x - this.effectPos.x, dot.y - this.effectPos.y)
      const isBig = distance < triggerDistance

      if (isBig) {
        dot.scale = Math.min(1, dot.scale + 0.1)
      } else {
        dot.scale = Math.max(0, dot.scale - 0.02)
      }

      const baseSize = this.dotSize - this.dotSize * dot.scale
      this.ctx.fillStyle = 'white'
      this.ctx.beginPath()
      this.ctx.arc(dot.x, dot.y, baseSize, 0, Math.PI * 2)
      this.ctx.fill()
    })

    this.dots.forEach(dot => {
      const distance = Math.hypot(dot.x - this.effectPos.x, dot.y - this.effectPos.y)
      const isBig = distance < triggerDistance

      if (isBig) {
        const scaledDistance = Math.abs(triggerDistance - distance)
        const particlePosScale = this.interpolate(
          dot.scale * scaledDistance,
          0,
          dot.scale * triggerDistance,
          0,
          triggerDistance
        )

        const drawParticle = (color: string, angleOffset: number) => {
          const particleDScale = this.interpolate(
            scaledDistance,
            0,
            triggerDistance,
            0.1,
            3.5
          )
          this.ctx.fillStyle = color
          this.ctx.beginPath()
          this.ctx.arc(
            dot.x + Math.cos(angleOffset) * particlePosScale,
            dot.y + Math.sin(angleOffset) * particlePosScale,
            this.dotSize * particleDScale,
            0,
            Math.PI * 2
          )
          this.ctx.fill()
        }

        drawParticle(dot.color1, dot.offsetAngle1)
        drawParticle(dot.color2, dot.offsetAngle2)
      }
    })

    requestAnimationFrame(this.draw)
  }

  resize = (): void => {
    this.width = document.documentElement.clientWidth
    this.height = document.documentElement.clientHeight

    const dpr = window.devicePixelRatio || 1
    this.canvas.width = window.innerWidth * dpr
    this.canvas.height = window.innerHeight * dpr
    this.canvas.style.width = document.documentElement.clientWidth + 'px'
    this.canvas.style.height = document.documentElement.clientHeight + 'px'

    this.ctx.scale(dpr, dpr)

    this.dotLayoutSize = findNearDivisor(this.width, this.height)
    this.dotSize = this.dotLayoutSize / 5
    this.dotOffset = (this.dotLayoutSize * 4) / 5

    this.dots.length = 0
    this.dots.push(...this.createDots())
  }

  private clamp = (value: number, min: number, max: number) => {
    return Math.min(Math.max(value, min), max)
  }

  private interpolate = (
    value: number,
    inputStart: number,
    inputEnd: number,
    outputStart: number,
    outputEnd: number
  ) => {
    return (
      outputStart +
      ((value - inputStart) / (inputEnd - inputStart)) *
        (outputEnd - outputStart)
    )
  }

  private updateMousePosition = (event: MouseEvent | Touch) => {
    const rect = this.canvas.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top
    this.mouse = { x: x, y: y }
  }

  private handleInteraction = (event: MouseEvent | Touch) => {
    this.updateMousePosition(event)
    if (this.activityFactor <= 0) {
      this.effectPos.x = this.mouse.x
      this.effectPos.y = this.mouse.y
      this.effectVel.x = 0
      this.effectVel.y = 0
    }
    this.lastMove = new Date().getTime()
    this.activityFactor += this.positiveDAF
  }

  private createDots = (): Dot[] => {
    const cols = Math.floor(this.width / this.dotLayoutSize)
    const rows = Math.floor(this.height / this.dotLayoutSize)

    return Array.from({ length: rows }, (_, yI) =>
      Array.from({ length: cols }, (_, xI) => {
        const x = xI * this.dotLayoutSize
        const y = yI * this.dotLayoutSize
        const randomAngle = Math.random() * 2 * Math.PI
        const randomColor = tinycolor('red').spin(Math.random() * 720 - 360)
        return {
          x: x + this.dotOffset / 2 + this.dotSize / 2,
          y: y + this.dotOffset / 2 + this.dotSize / 2,
          scale: 0,
          offsetAngle1: randomAngle,
          offsetAngle2: randomAngle + Math.PI,
          color1: randomColor.toString(),
          color2: randomColor.spin(360).toString()
        }
      })
    ).flat()
  }
}

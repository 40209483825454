// https://www.youtube.com/watch?v=p7IGZTjC008
// https://doi.org/10.1109/MCG.2011.51

import { Sketch } from './Sketch'
import { findNearDivisor } from './utils'

type Vec2 = {
  x: number
  y: number
}

class Color {
  r: number
  g: number
  b: number

  constructor(r = 255, g = 255, b = 255) {
    this.r = r
    this.g = g
    this.b = b
  }

  maxValue = () => Math.max(this.r, Math.max(this.g, this.b))
}

type Drop = {
  c: Vec2
  r: number
  vertices: Vec2[]
  color: Color
}

export class Drops implements Sketch {
  private canvas: HTMLCanvasElement
  private ctx: CanvasRenderingContext2D

  width: number
  height: number

  drops: Drop[] = []
  mouse?: Vec2 = undefined
  mouseMoveCount: number = 0

  dotLayoutSize: number
  dotSize: number
  dotOffset: number

  setup = async (canvas: HTMLCanvasElement): Promise<number> => {
    this.canvas = canvas
    this.ctx = (ctx_opt => {
      if (ctx_opt === null) throw new Error('Context is null')
      else return ctx_opt
    })(this.canvas.getContext('2d'))

    this.resize()

    window.addEventListener('resize', this.resize)
    document.addEventListener('mousemove', this.handleInteraction)
    document.addEventListener('touchmove', e => {
      e.preventDefault()
      this.handleInteraction(e.touches[0])
    })

    return 1
  }

  draw = (): void => {
    this.ctx.fillStyle = 'black'
    this.ctx.fillRect(0, 0, this.width, this.height)

    this.addDrop()
    this.drawDrops()

    requestAnimationFrame(this.draw)
  }

  resize = (): void => {
    this.width = document.documentElement.clientWidth
    this.height = document.documentElement.clientHeight

    const dpr = window.devicePixelRatio || 1
    this.canvas.width = window.innerWidth * dpr
    this.canvas.height = window.innerHeight * dpr
    this.canvas.style.width = document.documentElement.clientWidth + 'px'
    this.canvas.style.height = document.documentElement.clientHeight + 'px'

    this.dotLayoutSize = findNearDivisor(this.width, this.height)
    this.dotSize = this.dotLayoutSize / 5
    this.dotOffset = (this.dotLayoutSize * 4) / 5

    this.ctx.scale(dpr, dpr)
  }

  private addDrop = (x?: number, y?: number, r?: number, color?: Color): void => {
    let radius = r ?? this.dotSize // Math.random() * (this.height / 10) + 5

    let c: Vec2 = {
      x: x ??
        Math.round((Math.random() * this.width) / this.dotOffset) *
        this.dotOffset,
      y: y ??
        Math.round((Math.random() * this.height) / this.dotOffset) *
        this.dotOffset
    }

    let n_vertices = 200

    let vertices: Vec2[] = Array.from({ length: n_vertices }).map((_, i) => {
      let angle = (i / n_vertices) * (2 * Math.PI)
      return {
        x: radius * Math.cos(angle) + c.x,
        y: radius * Math.sin(angle) + c.y
      }
    })

    // marble old drops
    this.drops.forEach((drop, dropI) => {
      let dropIsIn = false
      drop.vertices = drop.vertices.map(v => {
        let m = Math.sqrt(Math.pow(v.x - c.x, 2) + Math.pow(v.y - c.y, 2))

        let x =
          c.x + (v.x - c.x) * Math.sqrt(1 + Math.pow(radius, 2) / Math.pow(m, 2))
        let y =
          c.y + (v.y - c.y) * Math.sqrt(1 + Math.pow(radius, 2) / Math.pow(m, 2))

        if (!dropIsIn && x > 0 && x < this.width && y > 0 && y < this.height)
          dropIsIn = true

        return {
          x,
          y
        }
      })

      if (!dropIsIn) {
        this.drops.splice(dropI, 1)
      }
    })

    this.drops.push({
      c,
      r: radius,
      vertices,
      color: color ?? new Color(255, 255, 255)
    })

    this.drops.forEach(drop => {
      // spread drop out if recently created to look like splash
      let colorValue = drop.color.maxValue()
      if (colorValue > 250) {
        drop.vertices = drop.vertices.map(v => ({
          x: drop.c.x + (v.x - drop.c.x) * ((1 / (255 - colorValue + 1)) / 20 + 1),
          y: drop.c.y + (v.y - drop.c.y) * ((1 / (255 - colorValue + 1)) / 20 + 1),
        }));
      }

      // spread out vertices on drop shape to avoid straight edges
      drop.vertices = this.applySurfaceTension(drop.vertices, 0.1);
    });

    // darken (age) drop
    for (let i = 0; i < this.drops.length; i++) {
      let drop = this.drops.at(i)
      if (drop == undefined || drop.color.maxValue() <= 5) {
        this.drops.splice(i, 1)
      } else {
        drop.color.r -= 0.3
        drop.color.g -= 0.3
        drop.color.b -= 0.3
      }
    }
  }

  // private addTine = (p1: Vec2, p2: Vec2) => {
  //   if (p1.x == p2.x && p1.y == p2.y) return

  //   let line = { x: p2.x - p1.x, y: p2.y - p1.y }

  //   let line_mag = Math.sqrt(line.x * line.x + line.y * line.y)

  //   let m = { x: line.x / line_mag, y: line.y / line_mag }
  //   let n = { x: -m.y, y: m.x }

  //   let alpha = line_mag / 8
  //   let lambda = 100

  //   this.drops.forEach(drop => {
  //     drop.vertices = drop.vertices.map(v => {
  //       let pma = { x: v.x - p2.x, y: v.y - p2.y }
  //       let pmadotn = pma.x * n.x + pma.y + n.y
  //       let d = Math.abs(pmadotn)

  //       let stuff = (alpha * lambda) / (d + lambda)

  //       let p = {
  //         x: v.x + stuff * m.x,
  //         y: v.y + stuff * m.y
  //       }

  //       return {
  //         x: p.x,
  //         y: p.y
  //       }
  //     })
  //   })
  // }

  private drawDrops = () => {
    for (let i = this.drops.length - 1; i >= 0; i--) {
      let drop = this.drops.at(i)
      if (!drop) continue

      this.ctx.fillStyle = `rgb(${drop.color.r}, ${drop.color.g}, ${drop.color.b})`
      this.ctx.beginPath()
      this.ctx.moveTo(drop.vertices[0].x, drop.vertices[0].y)
      drop.vertices.forEach(v => {
        this.ctx.lineTo(v.x, v.y)
      })
      this.ctx.fill()
    }
  }

  colorfulColors = [
    new Color(255, 0, 0),
    new Color(0, 255, 0),
    new Color(0, 0, 255),

    new Color(255, 255, 0),
    new Color(255, 0, 255),
    new Color(0, 255, 255),
  ]

  private updateMousePosition = (event: MouseEvent | Touch) => {
    const rect = this.canvas.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top

    let newMouse = { x, y }

    if (this.mouse !== undefined) {
      this.mouseMoveCount++
      if (this.mouseMoveCount == 5) {
        this.mouseMoveCount = 0

        let randomColor = this.colorfulColors[Math.floor(Math.random() * this.colorfulColors.length)]
        let randomColorCopy = new Color(randomColor.r, randomColor.g, randomColor.b)

        this.addDrop(this.mouse.x, this.mouse.y, this.dotSize, randomColorCopy)
      }
    }
    this.mouse = newMouse
  }

  private handleInteraction = (event: MouseEvent | Touch) => {
    this.updateMousePosition(event)
  }

  private applySurfaceTension = (vertices, tensionFactor = 0.1) => {
    return vertices.map((v, i, arr) => {
      const prev = arr[(i - 1 + arr.length) % arr.length];
      const next = arr[(i + 1) % arr.length];
      return {
        x: v.x + tensionFactor * (((prev.x + next.x) / 2) - v.x),
        y: v.y + tensionFactor * (((prev.y + next.y) / 2) - v.y)
      };
    });
  }
}
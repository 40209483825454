import { Sketch } from './Sketch'
import { ChaosGrid } from './ChaosGrid'
import { Drops } from './Drops'
import { Brot } from './Brot'

import Typed from 'typed.js'

const canvas = document.getElementById('canvas') as HTMLCanvasElement | null

if (!canvas) {
  throw new Error('Canvas not found')
}

const main = async () => {
  let currentSketchId = parseInt(localStorage.getItem('currentSketchId') || '0')
  let sketches = [/*Brot,*/ ChaosGrid, Drops]
  let sketch: Sketch = new sketches[currentSketchId]()

  let foundWorkingSketch = false
  while (!foundWorkingSketch) {
    const success = await sketch.setup(canvas)
    if (success === 1) {
      foundWorkingSketch = true
    } else {
      currentSketchId++
      sketch = new sketches[currentSketchId]()
    }
  }

  sketch.draw()

  currentSketchId = (currentSketchId + 1) % sketches.length
  localStorage.setItem('currentSketchId', currentSketchId.toString())
}

main()

// const vowels = 'o'
// const getPhoneticString = (length, startsWithVowel) => {
//   const consonants = 'nm'
//   let result = ''
//   for (let i = 0; i < length; i++) {
//     if ((i % 2 === 0 && !startsWithVowel) || (i % 2 !== 0 && startsWithVowel)) {
//       result += consonants.charAt(Math.floor(Math.random() * consonants.length))
//     } else {
//       result += vowels
//     }
//   }
//   return result
// }

const typed = new Typed('#header-name', {
  strings: Array.from({ length: 20 }, (_, i) => {
    // const phoneticLength =
    //   Math.round(
    //     Math.floor(Math.random() * Math.floor(window.innerWidth / 100))
    //   ) * 2
    // const phoneticString1 = getPhoneticString(phoneticLength, true)
    // return `${phoneticString1}`
    return "onom".repeat(Math.floor(Math.random() * Math.floor(window.innerWidth / 200)))
  }),
  loop: true,
  typeSpeed: 100,
  backSpeed: 30,
  startDelay: 1000,
  backDelay: 5000,
  showCursor: true
})

const email = 'tomsemail2100@gmail.com'
let emailLinkEl = document.getElementById('email-link')
emailLinkEl?.addEventListener('click', () => {
  navigator?.clipboard?.writeText(email)
  let oldEmailHtml = emailLinkEl.innerHTML
  emailLinkEl.innerHTML = `Copied <u>${email}</u> to clipboard`
  setTimeout(() => {
    emailLinkEl.innerHTML = oldEmailHtml
  }, 3000)
})
